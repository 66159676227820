/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param faculty 
 * @param specialty 
 * @param course 
 * @param degree 
 * @param educationForm 
 * @param department 
 */
@Serializable
internal data class EntityExtra (
    
    @SerialName("faculty")
    val faculty: kotlin.String? = null,
    
    @SerialName("specialty")
    val specialty: kotlin.String? = null,
    
    @SerialName("course")
    val course: kotlin.Int? = null,
    
    @SerialName("degree")
    val degree: kotlin.String? = null,
    
    @SerialName("educationForm")
    val educationForm: kotlin.String? = null,
    
    @SerialName("department")
    val department: kotlin.String? = null

) {

}

