/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import ru.campusapp.organization.generated.api.client.models.ScheduleInterval
import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param id 
 * @param updatedAt 
 * @param date 
 * @param week 
 * @param intervals 
 */
@Serializable
internal data class ScheduleDay (
    
    @SerialName("_id")
    val id: kotlin.String,
    
    @SerialName("updatedAt")
    val updatedAt: kotlin.String,
    
    @SerialName("date")
    val date: kotlin.String,
    
    @SerialName("week")
    val week: kotlin.String? = null,
    
    @SerialName("intervals")
    val intervals: kotlin.collections.List<ScheduleInterval>? = null

) {

}

