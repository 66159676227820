/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import ru.campusapp.organization.generated.api.client.models.Coordinates
import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param name 
 * @param address 
 * @param coordinate 
 */
@Serializable
internal data class LessonBuilding (
    
    @SerialName("name")
    val name: kotlin.String? = null,
    
    @SerialName("address")
    val address: kotlin.String? = null,
    
    @SerialName("coordinate")
    val coordinate: Coordinates? = null

) {

}

