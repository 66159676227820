/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import ru.campusapp.organization.generated.api.client.models.IntervalLesson
import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param start 
 * @param end 
 * @param lessons 
 * @param number 
 */
@Serializable
internal data class ScheduleInterval (
    
    @SerialName("start")
    val start: kotlin.String,
    
    @SerialName("end")
    val end: kotlin.String,
    
    @SerialName("lessons")
    val lessons: kotlin.collections.List<IntervalLesson>,
    
    @SerialName("number")
    val number: dev.icerock.moko.network.nullable.Nullable<kotlin.Int>? = null

) {

}

