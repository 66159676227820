/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param latitude 
 * @param longitude 
 */
@Serializable
internal data class Coordinates (
    
    @SerialName("latitude")
    val latitude: kotlin.Double,
    
    @SerialName("longitude")
    val longitude: kotlin.Double

) {

}

