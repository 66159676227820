package dev.icerock.moko.network

import io.ktor.client.statement.HttpResponse

data class NetworkResponse<T>(
    val httpResponse: HttpResponse,
    private val bodyReader: suspend (HttpResponse) -> T
) {
    suspend fun body(): T = bodyReader(httpResponse)
}
