/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param code 
 * @param variantId 
 * @param bannerType 
 * @param imageUrl 
 * @param linkUrl 
 */
@Serializable
internal data class LessonAdvertisement (
    
    @SerialName("code")
    val code: kotlin.String,
    
    @SerialName("variantId")
    val variantId: kotlin.String,
    
    @SerialName("bannerType")
    val bannerType: LessonAdvertisement.BannerType,
    
    @SerialName("imageUrl")
    val imageUrl: kotlin.String,
    
    @SerialName("linkUrl")
    val linkUrl: kotlin.String

) {

    /**
    * 
    * Values: "circle","bottom"
    */
    @Serializable
    enum class BannerType {
    
        @SerialName("circle")
        CIRCLE,
    
        @SerialName("bottom")
        BOTTOM;
    
    }

}

