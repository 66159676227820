/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.apis

import ru.campusapp.organization.generated.api.client.models.ActivatePromoBody
import ru.campusapp.organization.generated.api.client.models.BasicInfoBody
import ru.campusapp.organization.generated.api.client.models.BasicInfoContent
import ru.campusapp.organization.generated.api.client.models.CityOrganizations
import ru.campusapp.organization.generated.api.client.models.ErrorBody
import ru.campusapp.organization.generated.api.client.models.MessageBody
import ru.campusapp.organization.generated.api.client.models.OrganizationEntity
import ru.campusapp.organization.generated.api.client.models.UserInfoBody
import ru.campusapp.organization.generated.api.client.models.UserInfoContent

import dev.icerock.moko.network.NetworkResponse
import io.ktor.client.call.body
import io.ktor.client.HttpClient
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.request
import io.ktor.client.request.forms.FormDataContent
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpMethod
import io.ktor.http.Parameters
import io.ktor.http.takeFrom
import kotlinx.serialization.json.Json
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.SetSerializer
import kotlinx.serialization.builtins.serializer
import io.ktor.client.call.ReceivePipelineException
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.forms.submitFormWithBinaryData
import io.ktor.http.ContentDisposition
import io.ktor.http.HttpHeaders
import io.ktor.http.content.TextContent
import io.ktor.http.Headers
import io.ktor.utils.io.core.Input
import io.ktor.http.encodedPath
import io.ktor.http.path

internal interface DefaultApi {

    /**
    * 
    * Активация промо кода
    * @param activatePromoBody  (optional)
    * @return MessageBody
    */
    suspend fun activatePromo(activatePromoBody: ActivatePromoBody? = null) : MessageBody

    /**
    * 
    * Активация промо кода
    * @param activatePromoBody  (optional)
    * @return HttpResponse
    */
    suspend fun activatePromoResponse(activatePromoBody: ActivatePromoBody? = null) : NetworkResponse<MessageBody>

    /**
    * 
    * Поиск сущностей в организации. Выдается не пагинированный и не отсортированный список
    * @param organizationId  
    * @param type  
    * @param nameQuery  (optional)
    * @return kotlin.collections.List<OrganizationEntity>
    */
    suspend fun getOrganizationEntities(organizationId: kotlin.String, type: kotlin.String, nameQuery: kotlin.String? = null) : kotlin.collections.List<OrganizationEntity>

    /**
    * 
    * Поиск сущностей в организации. Выдается не пагинированный и не отсортированный список
    * @param organizationId  
    * @param type  
    * @param nameQuery  (optional)
    * @return HttpResponse
    */
    suspend fun getOrganizationEntitiesResponse(organizationId: kotlin.String, type: kotlin.String, nameQuery: kotlin.String? = null) : NetworkResponse<kotlin.collections.List<OrganizationEntity>>

    /**
    * 
    * Получение списка организаций (ВУЗов), с группировкой по городам
    * @return kotlin.collections.List<CityOrganizations>
    */
    suspend fun getOrganizations() : kotlin.collections.List<CityOrganizations>

    /**
    * 
    * Получение списка организаций (ВУЗов), с группировкой по городам
    * @return HttpResponse
    */
    suspend fun getOrganizationsResponse() : NetworkResponse<kotlin.collections.List<CityOrganizations>>

    /**
    * 
    * Получение текущей организации и списка объявлений
    * @param basicInfoBody  (optional)
    * @return BasicInfoContent
    */
    suspend fun updateBasic(basicInfoBody: BasicInfoBody? = null) : BasicInfoContent

    /**
    * 
    * Получение текущей организации и списка объявлений
    * @param basicInfoBody  (optional)
    * @return HttpResponse
    */
    suspend fun updateBasicResponse(basicInfoBody: BasicInfoBody? = null) : NetworkResponse<BasicInfoContent>

    /**
    * 
    * Регистрация и обновление пользователя
    * @param userInfoBody  (optional)
    * @return UserInfoContent
    */
    suspend fun updateUser(userInfoBody: UserInfoBody? = null) : UserInfoContent

    /**
    * 
    * Регистрация и обновление пользователя
    * @param userInfoBody  (optional)
    * @return HttpResponse
    */
    suspend fun updateUserResponse(userInfoBody: UserInfoBody? = null) : NetworkResponse<UserInfoContent>


    companion object {
        operator fun invoke(basePath: String = "https://api.campus.dewish.ru", httpClient: HttpClient, json: Json): DefaultApi = DefaultApiImpl(basePath, httpClient, json)
    }
}

internal class DefaultApiImpl(basePath: kotlin.String, httpClient: HttpClient, json: Json): DefaultApi {
    private val _basePath = basePath
    private val _httpClient = httpClient
    private val _json = json

    /**
    * 
    * Активация промо кода
    * @param activatePromoBody  (optional)
    * @return MessageBody
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun activatePromo(activatePromoBody: ActivatePromoBody?) : MessageBody {
        try {
            return activatePromoResponse(activatePromoBody).body()
        } catch (pipeline: ReceivePipelineException) {
            throw pipeline.cause
        }
    }

    /**
    * 
    * Активация промо кода
    * @param activatePromoBody  (optional)
    * @return HttpResponse
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun activatePromoResponse(activatePromoBody: ActivatePromoBody?) : NetworkResponse<MessageBody> {
        val builder = HttpRequestBuilder()

        builder.method = HttpMethod.Post
        builder.url {
            takeFrom(_basePath)
            encodedPath = encodedPath.let { startingPath ->
                path("v4/promocode/activate")
                return@let startingPath + encodedPath
            }
        }
        @Suppress("SENSELESS_COMPARISON")
        if(activatePromoBody != null) {
            builder.setBody(TextContent(
            
                _json.encodeToString(ActivatePromoBody.serializer(),
            
                activatePromoBody),
                ContentType.Application.Json.withoutParameters()
            ))
        }

        with(builder.headers) {
            append("Accept", "application/json")
        }

        val response = _httpClient.request(builder)

        val serializer = MessageBody.serializer()

        return NetworkResponse(
            httpResponse = response,
            bodyReader = { _json.decodeFromString(serializer, it.body()) }
        )
    }

    /**
    * 
    * Поиск сущностей в организации. Выдается не пагинированный и не отсортированный список
    * @param organizationId  
    * @param type  
    * @param nameQuery  (optional)
    * @return kotlin.collections.List<OrganizationEntity>
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun getOrganizationEntities(organizationId: kotlin.String, type: kotlin.String, nameQuery: kotlin.String?) : kotlin.collections.List<OrganizationEntity> {
        try {
            return getOrganizationEntitiesResponse(organizationId, type, nameQuery).body()
        } catch (pipeline: ReceivePipelineException) {
            throw pipeline.cause
        }
    }

    /**
    * 
    * Поиск сущностей в организации. Выдается не пагинированный и не отсортированный список
    * @param organizationId  
    * @param type  
    * @param nameQuery  (optional)
    * @return HttpResponse
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun getOrganizationEntitiesResponse(organizationId: kotlin.String, type: kotlin.String, nameQuery: kotlin.String?) : NetworkResponse<kotlin.collections.List<OrganizationEntity>> {
        val builder = HttpRequestBuilder()

        builder.method = HttpMethod.Get
        builder.url {
            takeFrom(_basePath)
            encodedPath = encodedPath.let { startingPath ->
                path("v3/organizations/$organizationId/entities")
                return@let startingPath + encodedPath
            }
            @Suppress("UNNECESSARY_SAFE_CALL")
            with(parameters) {
                type?.let { append("type", it.toString()) }
                nameQuery?.let { append("nameQuery", it.toString()) }
            }
        }

        with(builder.headers) {
            append("Accept", "application/json")
        }

        val response = _httpClient.request(builder)

        val serializer = OrganizationEntity.serializer()
.let {

ListSerializer(it)
}

        return NetworkResponse(
            httpResponse = response,
            bodyReader = { _json.decodeFromString(serializer, it.body()) }
        )
    }

    /**
    * 
    * Получение списка организаций (ВУЗов), с группировкой по городам
    * @return kotlin.collections.List<CityOrganizations>
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun getOrganizations() : kotlin.collections.List<CityOrganizations> {
        try {
            return getOrganizationsResponse().body()
        } catch (pipeline: ReceivePipelineException) {
            throw pipeline.cause
        }
    }

    /**
    * 
    * Получение списка организаций (ВУЗов), с группировкой по городам
    * @return HttpResponse
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun getOrganizationsResponse() : NetworkResponse<kotlin.collections.List<CityOrganizations>> {
        val builder = HttpRequestBuilder()

        builder.method = HttpMethod.Get
        builder.url {
            takeFrom(_basePath)
            encodedPath = encodedPath.let { startingPath ->
                path("v3/organizations")
                return@let startingPath + encodedPath
            }
        }

        with(builder.headers) {
            append("Accept", "application/json")
        }

        val response = _httpClient.request(builder)

        val serializer = CityOrganizations.serializer()
.let {

ListSerializer(it)
}

        return NetworkResponse(
            httpResponse = response,
            bodyReader = { _json.decodeFromString(serializer, it.body()) }
        )
    }

    /**
    * 
    * Получение текущей организации и списка объявлений
    * @param basicInfoBody  (optional)
    * @return BasicInfoContent
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun updateBasic(basicInfoBody: BasicInfoBody?) : BasicInfoContent {
        try {
            return updateBasicResponse(basicInfoBody).body()
        } catch (pipeline: ReceivePipelineException) {
            throw pipeline.cause
        }
    }

    /**
    * 
    * Получение текущей организации и списка объявлений
    * @param basicInfoBody  (optional)
    * @return HttpResponse
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun updateBasicResponse(basicInfoBody: BasicInfoBody?) : NetworkResponse<BasicInfoContent> {
        val builder = HttpRequestBuilder()

        builder.method = HttpMethod.Post
        builder.url {
            takeFrom(_basePath)
            encodedPath = encodedPath.let { startingPath ->
                path("v3/basic")
                return@let startingPath + encodedPath
            }
        }
        @Suppress("SENSELESS_COMPARISON")
        if(basicInfoBody != null) {
            builder.setBody(TextContent(
            
                _json.encodeToString(BasicInfoBody.serializer(),
            
                basicInfoBody),
                ContentType.Application.Json.withoutParameters()
            ))
        }

        with(builder.headers) {
            append("Accept", "application/json")
        }

        val response = _httpClient.request(builder)

        val serializer = BasicInfoContent.serializer()

        return NetworkResponse(
            httpResponse = response,
            bodyReader = { _json.decodeFromString(serializer, it.body()) }
        )
    }

    /**
    * 
    * Регистрация и обновление пользователя
    * @param userInfoBody  (optional)
    * @return UserInfoContent
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun updateUser(userInfoBody: UserInfoBody?) : UserInfoContent {
        try {
            return updateUserResponse(userInfoBody).body()
        } catch (pipeline: ReceivePipelineException) {
            throw pipeline.cause
        }
    }

    /**
    * 
    * Регистрация и обновление пользователя
    * @param userInfoBody  (optional)
    * @return HttpResponse
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun updateUserResponse(userInfoBody: UserInfoBody?) : NetworkResponse<UserInfoContent> {
        val builder = HttpRequestBuilder()

        builder.method = HttpMethod.Post
        builder.url {
            takeFrom(_basePath)
            encodedPath = encodedPath.let { startingPath ->
                path("v3/user")
                return@let startingPath + encodedPath
            }
        }
        @Suppress("SENSELESS_COMPARISON")
        if(userInfoBody != null) {
            builder.setBody(TextContent(
            
                _json.encodeToString(UserInfoBody.serializer(),
            
                userInfoBody),
                ContentType.Application.Json.withoutParameters()
            ))
        }

        with(builder.headers) {
            append("Accept", "application/json")
        }

        val response = _httpClient.request(builder)

        val serializer = UserInfoContent.serializer()

        return NetworkResponse(
            httpResponse = response,
            bodyReader = { _json.decodeFromString(serializer, it.body()) }
        )
    }

}
