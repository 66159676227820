/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param title 
 * @param url 
 */
@Serializable
internal data class ItemLink (
    
    @SerialName("title")
    val title: kotlin.String? = null,
    
    @SerialName("url")
    val url: kotlin.String? = null

) {

}

