/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param from 
 * @param to 
 */
@Serializable
internal data class ScheduleBody (
    
    @SerialName("from")
    val from: kotlin.String,
    
    @SerialName("to")
    val to: kotlin.String

) {

}

