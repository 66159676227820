/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import ru.campusapp.organization.generated.api.client.models.Organization
import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param id 
 * @param name 
 * @param code 
 * @param organizations 
 */
@Serializable
internal data class CityOrganizations (
    
    @SerialName("_id")
    val id: kotlin.String,
    
    @SerialName("name")
    val name: kotlin.String,
    
    @SerialName("code")
    val code: kotlin.String,
    
    @SerialName("organizations")
    val organizations: kotlin.collections.List<Organization>

) {

}

