/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import ru.campusapp.organization.generated.api.client.models.Coordinates
import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param id 
 * @param type 
 * @param city 
 * @param name 
 * @param description 
 * @param teachers 
 * @param rating 
 * @param ads 
 * @param logo 
 * @param updatedAt 
 * @param feed 
 * @param searchOnly 
 * @param coordinates 
 */
@Serializable
internal data class Organization (
    
    @SerialName("_id")
    val id: kotlin.String,
    
    @SerialName("type")
    val type: kotlin.String,
    
    @SerialName("city")
    val city: kotlin.String,
    
    @SerialName("name")
    val name: kotlin.String,
    
    @SerialName("description")
    val description: kotlin.String,
    
    @SerialName("teachers")
    val teachers: kotlin.Boolean,
    
    @SerialName("rating")
    val rating: kotlin.Boolean,
    
    @SerialName("ads")
    val ads: kotlin.Boolean,
    
    @SerialName("logo")
    val logo: kotlin.String,
    
    @SerialName("updatedAt")
    val updatedAt: kotlin.String,
    
    @SerialName("feed")
    val feed: kotlin.Boolean? = null,
    
    @SerialName("searchOnly")
    val searchOnly: kotlin.Boolean? = null,
    
    @SerialName("coordinates")
    val coordinates: Coordinates? = null

) {

}

