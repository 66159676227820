import androidx.compose.runtime.Composable
import dev.petuska.kmdc.linear.progress.MDCLinearProgress
import dev.petuska.kmdc.typography.MDCBody1
import dev.petuska.kmdc.typography.MDCBody2
import org.jetbrains.compose.web.dom.Div

sealed interface RemoteState<out T> {
    data object Loading : RemoteState<Nothing>
    data class Error(val throwable: Throwable) : RemoteState<Nothing>
    data class Success<T>(val data: T) : RemoteState<T>
}

@Composable
internal fun <T> RenderState(state: RemoteState<T>, dataContent: @Composable (T) -> Unit) {
    when (state) {
        is RemoteState.Error -> ErrorState(state.throwable)
        RemoteState.Loading -> LoadingState()
        is RemoteState.Success -> dataContent(state.data)
    }
}

@Composable
internal fun LoadingState() {
    MDCLinearProgress()
}

@Composable
internal fun ErrorState(throwable: Throwable) {
    Div(
        attrs = {
            classes("cmp-error-wrapper")
        }
    ) {
        MDCBody1(text = throwable.message.toString())
        MDCBody2(text = throwable.stackTraceToString())
    }
}
