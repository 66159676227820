/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import ru.campusapp.organization.generated.api.client.models.ItemLink
import ru.campusapp.organization.generated.api.client.models.LessonAdvertisement
import ru.campusapp.organization.generated.api.client.models.LessonBuilding
import ru.campusapp.organization.generated.api.client.models.LessonGroup
import ru.campusapp.organization.generated.api.client.models.LessonTeacher
import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param subject 
 * @param type 
 * @param classroom 
 * @param building 
 * @param groups 
 * @param subgroups 
 * @param links 
 * @param teachers 
 * @param advert 
 * @param comment 
 */
@Serializable
internal data class IntervalLesson (
    
    @SerialName("subject")
    val subject: kotlin.String,
    
    @SerialName("type")
    val type: kotlin.String? = null,
    
    @SerialName("classroom")
    val classroom: kotlin.String? = null,
    
    @SerialName("building")
    val building: LessonBuilding? = null,
    
    @SerialName("groups")
    val groups: kotlin.collections.List<LessonGroup>? = null,
    
    @SerialName("subgroups")
    val subgroups: kotlin.collections.List<kotlin.String>? = null,
    
    @SerialName("links")
    val links: kotlin.collections.List<ItemLink>? = null,
    
    @SerialName("teachers")
    val teachers: kotlin.collections.List<LessonTeacher>? = null,
    
    @SerialName("advert")
    val advert: LessonAdvertisement? = null,
    
    @SerialName("comment")
    val comment: kotlin.String? = null

) {

}

