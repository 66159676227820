/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import ru.campusapp.organization.generated.api.client.models.ScheduleDay
import ru.campusapp.organization.generated.api.client.models.ScheduleEntity
import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param entity 
 * @param days 
 */
@Serializable
internal data class EntitySchedule (
    
    @SerialName("entity")
    val entity: ScheduleEntity,
    
    @SerialName("days")
    val days: kotlin.collections.List<ScheduleDay>

) {

}

