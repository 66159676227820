/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import ru.campusapp.organization.generated.api.client.models.EntityExtra
import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param id 
 * @param name 
 * @param type 
 * @param updatedAt 
 * @param scheduleUpdatedAt 
 * @param subgroups 
 * @param extra 
 */
@Serializable
internal data class ScheduleEntity (
    
    @SerialName("_id")
    val id: kotlin.String,
    
    @SerialName("name")
    val name: kotlin.String,
    
    @SerialName("type")
    val type: ScheduleEntity.Type,
    
    @SerialName("updatedAt")
    val updatedAt: kotlin.String,
    
    @SerialName("scheduleUpdatedAt")
    val scheduleUpdatedAt: kotlin.String? = null,
    
    @SerialName("subgroups")
    val subgroups: kotlin.collections.List<kotlin.String>? = null,
    
    @SerialName("extra")
    val extra: EntityExtra? = null

) {

    /**
    * 
    * Values: "Group","Teacher"
    */
    @Serializable
    enum class Type {
    
        @SerialName("Group")
        GROUP,
    
        @SerialName("Teacher")
        TEACHER;
    
    }

}

