/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.models

import kotlinx.serialization.Serializable






import kotlinx.serialization.SerialName


/**
 * 
 * @param id 
 * @param name 
 */
@Serializable
internal data class LessonTeacher (
    
    @SerialName("_id")
    val id: kotlin.String? = null,
    
    @SerialName("name")
    val name: kotlin.String? = null

) {

}

