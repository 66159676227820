/**
* Client API
* Client API
*
* OpenAPI spec version: v4
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/
package ru.campusapp.organization.generated.api.client.apis

import ru.campusapp.organization.generated.api.client.models.EntitySchedule
import ru.campusapp.organization.generated.api.client.models.ErrorBody
import ru.campusapp.organization.generated.api.client.models.ScheduleBody

import dev.icerock.moko.network.NetworkResponse
import io.ktor.client.call.body
import io.ktor.client.HttpClient
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.request
import io.ktor.client.request.forms.FormDataContent
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpMethod
import io.ktor.http.Parameters
import io.ktor.http.takeFrom
import kotlinx.serialization.json.Json
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.SetSerializer
import kotlinx.serialization.builtins.serializer
import io.ktor.client.call.ReceivePipelineException
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.forms.submitFormWithBinaryData
import io.ktor.http.ContentDisposition
import io.ktor.http.HttpHeaders
import io.ktor.http.content.TextContent
import io.ktor.http.Headers
import io.ktor.utils.io.core.Input
import io.ktor.http.encodedPath
import io.ktor.http.path

internal interface ScheduleApi {

    /**
    * 
    * Получение расписания сущности
    * @param entityId  
    * @param from  
    * @param to  
    * @return EntitySchedule
    */
    suspend fun getScheduleGet(entityId: kotlin.String, from: kotlin.String, to: kotlin.String) : EntitySchedule

    /**
    * 
    * Получение расписания сущности
    * @param entityId  
    * @param from  
    * @param to  
    * @return HttpResponse
    */
    suspend fun getScheduleGetResponse(entityId: kotlin.String, from: kotlin.String, to: kotlin.String) : NetworkResponse<EntitySchedule>

    /**
    * 
    * Получение расписания сущности
    * @param entityId  
    * @param scheduleBody  (optional)
    * @return EntitySchedule
    */
    suspend fun getSchedulePost(entityId: kotlin.String, scheduleBody: ScheduleBody? = null) : EntitySchedule

    /**
    * 
    * Получение расписания сущности
    * @param entityId  
    * @param scheduleBody  (optional)
    * @return HttpResponse
    */
    suspend fun getSchedulePostResponse(entityId: kotlin.String, scheduleBody: ScheduleBody? = null) : NetworkResponse<EntitySchedule>


    companion object {
        operator fun invoke(basePath: String = "https://api.campus.dewish.ru", httpClient: HttpClient, json: Json): ScheduleApi = ScheduleApiImpl(basePath, httpClient, json)
    }
}

internal class ScheduleApiImpl(basePath: kotlin.String, httpClient: HttpClient, json: Json): ScheduleApi {
    private val _basePath = basePath
    private val _httpClient = httpClient
    private val _json = json

    /**
    * 
    * Получение расписания сущности
    * @param entityId  
    * @param from  
    * @param to  
    * @return EntitySchedule
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun getScheduleGet(entityId: kotlin.String, from: kotlin.String, to: kotlin.String) : EntitySchedule {
        try {
            return getScheduleGetResponse(entityId, from, to).body()
        } catch (pipeline: ReceivePipelineException) {
            throw pipeline.cause
        }
    }

    /**
    * 
    * Получение расписания сущности
    * @param entityId  
    * @param from  
    * @param to  
    * @return HttpResponse
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun getScheduleGetResponse(entityId: kotlin.String, from: kotlin.String, to: kotlin.String) : NetworkResponse<EntitySchedule> {
        val builder = HttpRequestBuilder()

        builder.method = HttpMethod.Get
        builder.url {
            takeFrom(_basePath)
            encodedPath = encodedPath.let { startingPath ->
                path("v3/entities/$entityId/schedule")
                return@let startingPath + encodedPath
            }
            @Suppress("UNNECESSARY_SAFE_CALL")
            with(parameters) {
                from?.let { append("from", it.toString()) }
                to?.let { append("to", it.toString()) }
            }
        }

        with(builder.headers) {
            append("Accept", "application/json")
        }

        val response = _httpClient.request(builder)

        val serializer = EntitySchedule.serializer()

        return NetworkResponse(
            httpResponse = response,
            bodyReader = { _json.decodeFromString(serializer, it.body()) }
        )
    }

    /**
    * 
    * Получение расписания сущности
    * @param entityId  
    * @param scheduleBody  (optional)
    * @return EntitySchedule
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun getSchedulePost(entityId: kotlin.String, scheduleBody: ScheduleBody?) : EntitySchedule {
        try {
            return getSchedulePostResponse(entityId, scheduleBody).body()
        } catch (pipeline: ReceivePipelineException) {
            throw pipeline.cause
        }
    }

    /**
    * 
    * Получение расписания сущности
    * @param entityId  
    * @param scheduleBody  (optional)
    * @return HttpResponse
    */
    @Suppress("UNCHECKED_CAST")
    override suspend fun getSchedulePostResponse(entityId: kotlin.String, scheduleBody: ScheduleBody?) : NetworkResponse<EntitySchedule> {
        val builder = HttpRequestBuilder()

        builder.method = HttpMethod.Post
        builder.url {
            takeFrom(_basePath)
            encodedPath = encodedPath.let { startingPath ->
                path("v3/entities/$entityId/schedule")
                return@let startingPath + encodedPath
            }
        }
        @Suppress("SENSELESS_COMPARISON")
        if(scheduleBody != null) {
            builder.setBody(TextContent(
            
                _json.encodeToString(ScheduleBody.serializer(),
            
                scheduleBody),
                ContentType.Application.Json.withoutParameters()
            ))
        }

        with(builder.headers) {
            append("Accept", "application/json")
        }

        val response = _httpClient.request(builder)

        val serializer = EntitySchedule.serializer()

        return NetworkResponse(
            httpResponse = response,
            bodyReader = { _json.decodeFromString(serializer, it.body()) }
        )
    }

}
